import { Box, Image, Center, Circle, Grid, GridItem } from '@chakra-ui/react';
import { chakra } from '@chakra-ui/react';
import { ExternalLinkWrapper } from '@components/ExternalLink';
import { MotionBox } from '@components/MotionBox';
import { Play } from '@components/Icons';
import { SanityImageAsset } from '@sanity/asset-utils';
import { useCarouselContext } from '@components/Carousel';
import { useGridAlignment, GridAlignmentHeight } from '@components/GridAlignmentWrapper';
import * as React from 'react';
import AspectRatioWrapper from '@components/AspectRatioWrapper';
import SanityMuxPlayer from 'sanity-mux-player';
import TransitionBox from '@components/TransitionBox';

const StyledSanityMuxPlayer = chakra(SanityMuxPlayer);

const VideoOverlay = (props: { thumbnailUrl: string; onClick: () => void; isPlaying?: boolean; hasPlayed?: boolean }) => {
	const [isHovering, setIsHovering] = React.useState(false);

	return (
		<MotionBox
			animate={{ visibility: !props.isPlaying ? 'visible' : 'hidden' }}
			boxSize="100%"
			cursor="pointer"
			initial={{ visibility: 'hidden' }}
			onClick={props.onClick}
			position="relative"
		>
			{!props.isPlaying && !props.hasPlayed && (
				<Box boxSize="100%" position="absolute">
					<Image src={props.thumbnailUrl} alt="" boxSize="100%" objectFit="cover" objectPosition="center center" />
				</Box>
			)}
			<Center boxSize="100%" position="absolute">
				<Circle
					_hover={{ bg: 'white' }}
					bg="rgba(0,0,0,0.25)"
					border={'0.2em solid white'}
					onMouseEnter={() => setIsHovering(true)}
					onMouseLeave={() => setIsHovering(false)}
					padding="4"
					role="group"
					size="5em"
				>
					<Play fontSize="2.5em" marginLeft={'0.2em'} fill={isHovering ? 'black' : 'white'} />
				</Circle>
			</Center>
		</MotionBox>
	);
};

export interface MuxVideoData {
	data: any;
	muxVideo: any;
	playbackId: any;
	playerOptions: any;
	thumbTime: any;
}

export const MuxVideo: React.FC<{
	autoPlay?: boolean;
	background?: boolean;
	externalLink?: string;
	gridHeight?: GridAlignmentHeight;
	muxVideoAsset: MuxVideoData;
	thumbnailAsset?: SanityImageAsset;
}> = (props) => {
	const muxRef = React.createRef();
	const [videoElement, setVideoElement] = React.useState<HTMLVideoElement | undefined>();
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const [hasPlayed, setHasPlayed] = React.useState(false);

	const gridAlignment = useGridAlignment();
	const carouselContext = useCarouselContext();

	const effectiveGridHeight = gridAlignment?.gridHeight ?? props.gridHeight;
	const isBackgroundOrAutoPlay = props.background || props.autoPlay;

	const { width, height, aspectRatio, thumbnailUrl } = React.useMemo(() => {
		if (!props.muxVideoAsset) return null;

		const playbackId = props.muxVideoAsset.playbackId;
		let thumbnailUrl: string;

		if (props.thumbnailAsset?.url) {
			thumbnailUrl = `${props.thumbnailAsset?.url}?q=90&w=1920`;
		}
		else {
			thumbnailUrl = `https://image.mux.com/${playbackId}/thumbnail.jpg?time=${props.muxVideoAsset.thumbTime ?? 0}`;
		}

		const [width, height] = props.muxVideoAsset.data.aspect_ratio.split(':');
		const aspectRatio = width / height;

		return {
			aspectRatio,
			height,
			thumbnailUrl,
			width,
		};
	}, [props.muxVideoAsset]);

	React.useEffect(() => {
		if (muxRef.current) {
			//@ts-ignore
			setVideoElement(muxRef.current.getVideoElement());
		}
	}, [muxRef, setVideoElement]);

	React.useEffect(() => {
		if (videoElement) {
			videoElement.addEventListener(
				'progress',
				function () {
					setIsLoaded(true);
				},
				false,
			);
		}
	}, [videoElement, setIsLoaded]);

	React.useEffect(() => {
		if (videoElement) {
			videoElement.onended = (event) => {
				setIsPlaying(false);

				if (carouselContext) {
					carouselContext.requestPlay();
				}
			};
		}
	}, [videoElement, setIsPlaying, carouselContext]);

	const play = React.useCallback(() => {
		if (videoElement && !isPlaying) {
			const playPromise = videoElement.play();

			if (playPromise !== undefined) {
				playPromise
					.then((_) => {
						setIsPlaying(true);
						setHasPlayed(true);

						if (carouselContext) {
							carouselContext.requestPause();
						}
					})
					.catch((error) => {
						// Play was prevented
						console.log('play promise error:', error);
					});
			}
		}
	}, [videoElement, setIsPlaying, setHasPlayed, carouselContext, isPlaying]);

	const stop = React.useCallback(() => {
		if (videoElement && isPlaying) {
			videoElement.pause();
			videoElement.currentTime = 0;
			setIsPlaying(false);

			if (carouselContext) {
				carouselContext.requestPlay();
			}
		}
	}, [videoElement, setIsPlaying, carouselContext, isPlaying]);

	const onVideoPreviewClick = React.useCallback(() => {
		!isPlaying ? play() : stop();
	}, [isPlaying, play, stop]);

	React.useEffect(() => {
		if (props.autoPlay) {
			play();
		}
	}, [props.autoPlay, play]);

	// stop when carousel plays
	// React.useEffect(() => {
	//   if (carouselContext?.isActive && isPlaying && !isBackgroundOrAutoPlay) {
	//     stop();
	//   }
	// }, [carouselContext?.isActive, isPlaying, stop, isBackgroundOrAutoPlay]);

	React.useEffect(() => {
		if (isPlaying) {
			stop();
		}
	}, [carouselContext?.isActive]);

	return (
		<ExternalLinkWrapper href={props.background && props.externalLink}>
			<AspectRatioWrapper ratio={effectiveGridHeight == 'row' ? undefined : aspectRatio}>
				<TransitionBox isLoaded={isLoaded}>
					<Grid boxSize="100%" gridTemplateRows="1fr" gridAutoRows="100%" gridAutoColumns="100%">
						<GridItem gridColumn="1/-1" gridRow="1" width="100%">
							<StyledSanityMuxPlayer
								ref={muxRef}
								assetDocument={props.muxVideoAsset}
								autoload={true}
								autoplay={isBackgroundOrAutoPlay}
								showControls={!isBackgroundOrAutoPlay && isPlaying}
								muted={isBackgroundOrAutoPlay}
								loop={props.background}
								poster
								playsInline
								sx={
									effectiveGridHeight == 'row' && {
										'height': '100% !important',
										'> div': {
											height: '100%',
										},
										'video': {
											objectFit: 'cover',
											height: '100%',
										},
									}
								}
							/>
						</GridItem>
						{!props.background && (
							<GridItem gridArea="1/-1" gridRow="1">
								<VideoOverlay thumbnailUrl={thumbnailUrl} onClick={onVideoPreviewClick} isPlaying={isPlaying} hasPlayed={hasPlayed} />
							</GridItem>
						)}
					</Grid>
				</TransitionBox>
			</AspectRatioWrapper>
		</ExternalLinkWrapper>
	);
};

export interface VideoModuleProps {
	url: string;
}
export const VideoModule: React.FC<VideoModuleProps> = (props) => {
	const gridAlignment = useGridAlignment();

	return (
		<Box
			as="video"
			src={props.url}
			autoPlay
			muted
			loop
			playsInline
			width="100%"
			sx={
				gridAlignment?.gridHeight == 'row' && {
					height: '100%',
					objectFit: 'cover',
					objectPosition: 'center center',
					verticalAlign: 'middle',
				}
			}
		/>
	);
};
